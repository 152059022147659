import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import "./style.css"
import useLang from "../../hooks/useLang"

const ServicesPage = ({
  services,
  contactUsButton,
  canNotFindWhatIamLookingForSection,
}) => {
  const isAr = useLang() === "ar"
  return (
    <div className="services-page">
      <div className="container">
        {services.map(({ title, description, image }) => (
          <div className="row mb-10">
            <div className="col-xs-12 col-sm-6 col-md-5 ">
              <div className="about-additional-img">
                <Img fluid={image?.childImageSharp?.fluid || {}} />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-md-offset-1">
              <div className="about-additional-content">
                <h3 className="inherit-title">{title}</h3>
                <div className="about-additional-text">{description}</div>
                <Link
                  className="btn btn-primary"
                  to={isAr ? "/ar/contact/" : "/contact/"}
                >
                  {contactUsButton}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="b-ask">
        <div className="b-ask-content text-center">
          <h3 className="big-title-mod">
            {canNotFindWhatIamLookingForSection.title}
          </h3>
          <div className="b-text">
            <p>{canNotFindWhatIamLookingForSection.description}</p>
          </div>
          <Link className="btn btn-primary" to={`${isAr ? "/ar" : ""}/contact`}>
            {canNotFindWhatIamLookingForSection.askQuestionButton}
          </Link>
        </div>
      </div>
    </div>
  )
}
export default ServicesPage
