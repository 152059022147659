import React from "react"
import "./style.css"
import MainAreaSection from "../MainAreaSection"
import useLang from "../../hooks/useLang"

function ServicesMainArea({ title, img, description }) {
  const currentPage = useLang() === "ar" ? "الخدمات" : "Services"
  return (
    <MainAreaSection img={img} title={title} currentPage={currentPage}>
      <div className="heading-text">
        <p>{description}</p>
      </div>
    </MainAreaSection>
  )
}
export default ServicesMainArea
