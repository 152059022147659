import React from "react"

import Layout from "../components/layout"
import ServicesMainArea from "../components/ServicesMainArea"
import ServicesPage from "../components/ServicesPage"
import { graphql } from "gatsby"
import Arrow from "../components/Arrow"

export default function Services({
  data: {
    markdownRemark: {
      frontmatter: {
        title,
        description,
        contactUsButton,
        lang,
        canNotFindWhatIamLookingFor,
        image,
      },
    },
    services: { edges },
  },
}) {
  const services = edges.map(({ node }) => node.frontmatter)
  return (
    <Layout lang={lang} title={title}>
      <ServicesMainArea
        img={image?.childImageSharp.fluid.src}
        title={title}
        description={description}
      />
      <div className="b-homepage-content">
        <div className="b-layer-main">
          <Arrow />
          <ServicesPage
            contactUsButton={contactUsButton}
            canNotFindWhatIamLookingForSection={canNotFindWhatIamLookingFor}
            services={services}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $lang: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        lang
        contactUsButton
        image {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        canNotFindWhatIamLookingFor {
          title
          description
          askQuestionButton
        }
      }
    }
    services: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(content/services)/.*\\\\.md$/" }
        frontmatter: { lang: { eq: $lang } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
